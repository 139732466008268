import React, { useState, useEffect } from "react";
import { getOverallparticularLead } from "../maketingComponents/actioncreator";
import { firstDateOfMonth, formatDate, lastDateOfMonth, monthStartDate, reverseFormat, subtractOneDay, todayDate } from "../maketingComponents/utils/utils";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import moment from 'moment'

const ConversionRation = ({ admin }) => {
    const [nameOpportunity, setNameopportunity] = useState([])
    const [dataOpportunity, setDataopportunity] = useState([])
    const [noOfEntry, setnoOfEntry] = useState(10);
    const [pageNo, setpageNo] = useState(1);

    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
        minDate: monthStartDate(),
    });
    const [rejectReasonData, setRejectReasonData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [optionsData, setOptionsData] = useState('');
    const [today, settoday] = useState(todayDate());
    const [dateType, setDateType] = useState('apply');

    useEffect(() => {
        getdataapi();
    }, []);

    const getdataapi = () => {
        getOverallparticularLead(
            payload,
            pageNo,
            noOfEntry,
            dateType,
            (callBack) => {

                setRejectReasonData(callBack);
                makegraphDataOpportunity(callBack)


            },
            (error) => {
                console.log(error);
            }
        );
    };
    const makegraphDataOpportunity = (To) => {
        let name = [];
        let data = [];

        for (const item of To) {
            const { totDisburseCount, totRejectCount } = item;

            name.push(totRejectCount);

            data.push(totDisburseCount);
        }

        setNameopportunity(name);
        setDataopportunity(data);

        console.log(Object.values(nameOpportunity))

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newPayload = {
            ...payload,
            [name]: formatDate(value),
        };
        // if (name === "fromDate") {
        //     newPayload.toDate = lastDateOfMonth(value);
        // } else {
        //     newPayload.fromDate = firstDateOfMonth(value);
        // }
        if (name === "fromDate") {
            newPayload.toDate = payload.toDate;
        } else {
            newPayload.fromDate = payload.fromDate;
        }
        setPayload(newPayload);
    };




    var NewOptions = {
        series: [{
            name: 'Rejected',
            data: Object.values(nameOpportunity)
        }, {
            name: 'Disbursed',
            data: Object.values(dataOpportunity)
        }],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '90%',
                barWidth: '90%',
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            // fontWeight: 900
                        }
                    }
                }
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },

        xaxis: {
            categories: Object.keys(dataOpportunity),
            // labels: {
            //     formatter: function (val) {
            //         return val + "K"
            //     }
            // }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            // y: {
            //     formatter: function (val) {
            //         return val + "K"
            //     }
            // }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: ['#4B49AC', '#98BDFF',],

    };
    const handleType=(data)=>{
        setDateType(data)
    }

    return (
        <div>
            <div className="col-sm-12 col-xs-12 shadow" style={{ marginBottom: '20px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '10px', minHeight: '600px' }}>
                <div className="bg-white p-4 " style={{ padding: '15px' }}>
                    <h5 style={{ color: '#1d3557', fontWeight: '700' }}> <strong style={{ paddingTop: '10px' }}>Conversion Ratio</strong></h5>
                    <div className="row">
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                            From
                            <input
                                type="date"
                                id="startDate"
                                placeholder="DD/MM/YY"
                                name="fromDate"
                                value={reverseFormat(payload.fromDate)}
                                onChange={handleChange}
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>

                        <div className="col-sm-2 col-xs-12">
                            <div className="filterHeaders" style={{ marginTop: "4px" }}>
                                to
                            </div>

                            <input
                                type="date"
                                id="endDate"
                                name="toDate"
                                max={reverseFormat(subtractOneDay(today))}
                                value={reverseFormat(subtractOneDay(payload.toDate))}
                                onChange={handleChange}
                                placeholder="DD/MM/YY"
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>
                        {/* <div className="col-sm-2 col-xs-12">
                            <div className="filterHeaders" style={{ marginTop: "4px" }}>
                                Date Type
                                <select className="form-control" value={dateType} onChange={(e) => handleType(e.target.value)}>
                                    <option value={''}>Select Value</option>
                                    <option value={'disburseDate'}>Disburse</option>
                                    <option value={'apply'}>Apply</option>

                                </select>
                            </div>
                        </div> */}
                        <div className="col-sm-2 col-xs-12">
                            <button
                                className="btn btn-primary mt-4 "
                                style={{
                                    color: "#2B78FF",
                                    borderColor: "#2B78FF",
                                    backgroundColor: "white",
                                    marginTop: "23px",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                                onClick={getdataapi}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>


                    <hr />
                    <div className="row">
                        <div className="col-sm-6 col-xs-12 other-table">
                            <div className='marketing-table' style={{ fontSize: '12px', width: '97%', marginRight: 'auto', overflow: 'auto' }} >
                                <table>
                                    <thead style={{ background: '#b9d8f9', color: '#000', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                        <tr className="thead-class">
                                            <th style={{ width: '20%' }} className="">
                                                <b>{rejectReasonData !== undefined && rejectReasonData !== '' && rejectReasonData !== null && rejectReasonData[0] !== undefined && rejectReasonData[0] !== '' && rejectReasonData[0] !== null && rejectReasonData[0].aggregatorName !== undefined ? 'Aggregator Name' : 'Date'}</b>
                                            </th>
                                            <th style={{ width: '20%' }} className="">
                                                <b>Total Leads</b>
                                            </th>

                                            <th style={{ width: '20%' }} className="">
                                                <b>Total Disbursed</b>
                                            </th>
                                            <th style={{ width: '20%' }} className="">
                                                <b>Total Rejected</b>
                                            </th>
                                            <th style={{ width: '20%' }} className="">
                                                <b>Conversion Ratio</b>
                                            </th>
                                            <th style={{ width: '20%' }} className="">
                                                <b>Rejection %</b>
                                            </th>

                                        </tr>
                                    </thead>
                                    {rejectReasonData.length > 0 ?
                                        <tbody style={{ height: '800px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                            {Object.keys(rejectReasonData).map((item, index) => {
                                                return (
                                                    <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                                                        <td style={{ width: '20%' }}>
                                                            {rejectReasonData[item].loanApplyDate !== undefined ? moment(rejectReasonData[item].loanApplyDate).format('DD-MM-YYYY') : rejectReasonData[item].date}
                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            {rejectReasonData[item].totalCount}
                                                        </td>
                                                        {/* <td style={{width:'20%'}}>
                          {rejectReasonData[item].totalApplied}
                        </td> */}
                                                        <td style={{ width: '20%' }}>
                                                            {rejectReasonData[item].totDisburseCount}
                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            {rejectReasonData[item].totRejectCount}
                                                        </td>
                                                        <td style={{ width: '20%' }}>

                                                            {rejectReasonData[item].conversionRatio?.toFixed(2)}%

                                                        </td>
                                                        <td style={{ width: '20%' }}>

                                                            {rejectReasonData[item].rejectionRatio?.toFixed(2)}%

                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                            {/* <tr className="thead-class" style={{ fontWeight: 'bold', background: '#B9D8F973' }}>
                                                <td style={{ borderBottomLeftRadius: '10px', width: '35%' }}><b>Total</b></td>
                                                <td style={{ width: '30%' }}>
                                                    <b>{rejectReasonData.reduce((total, data) => total + data.count, 0)}</b>
                                                </td>
                                                <td style={{ borderBottomRightRadius: '10px' }}></td>
                                            </tr> */}

                                        </tbody>
                                        :
                                        <tbody style={{ height: '800px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>

                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)', padding: '10px', borderRadius: '10px', marginTop: '21px' }}>
                                <h5><b>Conversion Ratio</b></h5>
                                <ReactApexChart options={NewOptions} series={NewOptions.series} type="bar" height={rejectReasonData.length > 7 ? 600 : rejectReasonData < 3 ? 200 : 400} width={'100%'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConversionRation;
