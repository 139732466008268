import React, { useState, useEffect } from "react";
import NoDataimg from '../../../../images/nodata.png'
import axios from 'axios';
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/Loader.component'
const EmaiLKey = ({editHandler}) => {
    const [loader,setLoader] =useState(false)
    const [rolesList, setRolesList] = useState([])

    useEffect(() => {
        getRoleList()
    }, [])

    const getRoleList = async () => {
        setLoader(true)
        try {
            setLoader(false)
            const result = await axios.get(APIS.GET_ALL_USER_ROLE)
            if (result && result.data?.length > 0) {
                setRolesList(result.data)
            }
        }catch{
            setLoader(false)
            setRolesList([])
        }
    
    }

    return (
        <div className="container">
            {
                loader ?
                <Loader />
           :'' }
            {rolesList && rolesList?.length > 0 ?
                <div className="phocket-table-new" style={{ overflowX: 'auto',overflowY:'auto',height:window.innerWidth > 1800 ? '717px':'420px', marginTop: '0px', marginBottom: '30px',boxShadow:'1px 6px 10px 1px #dbdbdb' }}>
                    <table style={{ marginBottom: '10px',position:'relative',zIndex:'1',padding:'0px 0px 10px 0px' }}>
                        <thead  style={{position:'sticky',zIndex:'0',top:'0'}}>
                            <tr>
                                <th className="text-center" style={{borderTopLeftRadius:'0px'}}>S.No.</th>
                                <th className="text-center">Role Id</th>
                                <th className="text-center" style={{borderTopRightRadius:'0px'}}>Role Name</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rolesList ? rolesList.map((data, index) => (
                                <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{data.roleId}</td>
                                    <td className="text-center">{data.roleName}</td>
                                    <td>
                                    <div className="edits">
                                        <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" style={{cursor:'pointer'}} onClick={() => editHandler( data)}></i>&nbsp;&nbsp;
                                        {/* <i className="fa fa-trash-o fa-lg" aria-hidden="true" style={{cursor:'pointer'}} onClick={() => deleteEntry( data)}></i> */}
                                    </div>
                                </td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </table>

                </div>
                :
                <div className="text-center">
                    <img src={NoDataimg} />
                </div>
            }
        </div>
    )
}
export default EmaiLKey