import React, { useEffect, useState } from "react";
import { getAllEmployees, deleteEmployeeById, getAttendenceApi } from "../../targetactioncreator";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../../images/nodata.png';
import AddAttendaence from "../../../../../presentationals/Popup/addAttendence";
import { ROLE } from "../../../../../../utils/Constant";

const EmpList = ({ editHandler, admin, showButton }) => {
    console.log(admin.emailId)
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [employeeData, setEmpData] = useState([]);
    const [editData, setEditData] = useState('');
    const [addPopupState, setaddPopupState] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [month, setMonth] = useState('January'); // Default to current month
    const [year, setYear] = useState(new Date().getFullYear()); // Default to current year
    const [attendanceData, setAttendanceData] = useState([]);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2022 + 1 }, (_, i) => 2022 + i);

    const months = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ];

    const handleToggle = () => { };
    const handleRestart = () => { };

    const handlebtnclick = (type, data) => {
        console.log(data);
        setLoader(true);
        deleteEmployeeById(data?.id, (callback) => {
            console.log(callback);
            setLoader(false);
            if (callback.status == '200') {
                getEmployees();
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        });
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        getEmployees();
    }, []);

    const getEmployees = () => {
        setLoader(true);
        getAllEmployees(callback => {
            console.log(callback);
            setLoader(false);
            if (callback && callback?.status === 200) {
                setEmpData(callback.data);
            }
        });
    };

    const closePopup = () => {
        setPopup(false);
    };

    const addPopup = (data) => {
        setaddPopupState(true);
        setEditData(data);
    };

    const closeDisbursePopup = () => {
        setaddPopupState(false);
        getEmployees();
    };

    const showDetailEmp = (data) => {
        setSelectedEmployee(data);
        setShowDetails(true);
        calculateAttendance(data.employeeID); // Call attendance on employee selection
    };

    const closeDetailView = () => {
        setShowDetails(false);
        setSelectedEmployee(null);
        setAttendanceData([]);
        setMonth('')
        setYear('')
    };

    const calculateAttendance = (employeeId) => {
        getAttendenceApi(employeeId, month, year, callback => {
            if (callback && callback.status === 200) {
                setAttendanceData(callback.data); // Store attendance data
            } else {
                setAttendanceData([]); // Clear if no data
            }
        });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        if (name === "month") {
            setMonth(value);
        } else if (name === "year") {
            setYear(value);
        }
    };
    const groupByDepartment = employeeData.length > 0 ? employeeData.reduce((acc, employee) => {
        const dept = employee.departmentName || 'Unassigned';
        if (!acc[dept]) acc[dept] = [];
        acc[dept].push(employee);
        return acc;
    }, {}) : "";

    return (
        <>
            {showDetails ? <button className="btn btn-primary" style={{ marginTop: '30px' }} onClick={closeDetailView}>Back to list</button> : ""}

            <div className={`${window.innerWidth > 1800 ? 'container-fluid' : 'container-fluid'} Executor`}>
                {loader && <Loader />}
                {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
                {addPopupState && <AddAttendaence editData={editData} admin={admin} closeDisbursePopup={closeDisbursePopup} />}
                {console.log(Object.entries(employeeData))}
                {!showDetails ? (
                    // employeeData.length > 0 ? (
                    <div className='' style={{ background: '#f2f2f2', padding: '10px', margin: '50px auto', borderRadius: '5px', overflow: 'auto' }}>

                        {Object.entries(employeeData).map((data, i) => (
                            <>
                                {console.log(data)}
                                <div style={{ background: '#fff', padding: '10px', marginTop: '10px', borderRadius: '5px' }}>
                                    <h3>{data[0]}</h3>
                                    {Object.keys(data[1]).length > 0 ? (
                                        Object.entries(data[1]).map((innerData, i) => {
                                            return (
                                                <div key={i} className='phocket-table-new'>
                                                    <h4>{innerData[0]}</h4>
                                                    <table style={{ marginBottom: '10px' }}>
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                                                        admin.rolelist.indexOf(ROLE.EMP_Details) >= 0 ?
                                                                            <th style={{ textAlign: 'center' }}>Details</th>
                                                                            : '' : ''}
                                                                <th style={{ textAlign: 'center' }}>Employee Name</th>
                                                                {showButton ? <th style={{ textAlign: 'center' }}>Add Attendance</th> : ""}

                                                                <th style={{ textAlign: 'center' }}> Attendance</th>
                                                                <th style={{ textAlign: 'center' }}>Employee Type</th>
                                                                <th style={{ textAlign: 'center' }}>Department</th>
                                                                <th style={{ textAlign: 'center' }}>Joining Date</th>
                                                                {/* <th style={{textAlign:'center'}}>Salary</th>
                                                        <th style={{textAlign:'center'}}>Variable</th>
                                                        <th style={{textAlign:'center'}}>Other Components</th>
                                                        <th style={{textAlign:'center'}}>Active</th>
                                                        <th style={{textAlign:'center'}}>In-Hand Amount</th>
                                                        <th style={{textAlign:'center'}}>Tax Deducted</th> */}
                                                                {showButton ? <th style={{ textAlign: 'center' }}>Edit</th> : ""}
                                                                {showButton ? <th style={{ textAlign: 'center' }}>Delete</th> : ""}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {innerData[1].map((employee, j) => (
                                                                <tr key={j} style={{ background: '#fff', padding: '5px', borderRadius: '5px', textAlign: 'center' }}>
                                                                    {console.log(employee)}
                                                                    {admin.rolelist !== undefined && admin.rolelist.length > 0 && admin.rolelist.indexOf(ROLE.EMP_Details) >= 0 ? (
                                                                        <td>
                                                                            <i className="fa fa-info" style={{ cursor: 'pointer' }} onClick={() => showDetailEmp(employee)}></i>
                                                                        </td>
                                                                    ) : null}
                                                                    <td className=''>{employee.employeeName}</td>
                                                                    {showButton ? <td>
                                                                        <button className="btn btn-primary" onClick={() => addPopup(employee)}>Add</button>
                                                                    </td> : ""}
                                                                    <td>{employee.presentDays}/{employee.totalDays}</td>
                                                                    <td>{employee.employeeType}</td>
                                                                    <td>{employee.departmentName}</td>
                                                                    <td>{employee.joiningDate}</td>
                                                                    {showButton && (
                                                                        <>
                                                                            <td>
                                                                                <i className="fa fa-pencil-square-o fa-lg" onClick={() => editHandler(employee)}></i>
                                                                            </td>
                                                                            <td>
                                                                                <i className="fa fa-trash-o fa-lg" onClick={() => handlebtnclick('delete', employee)}></i>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        ))}

                    </div>

                ) : (
                    <>
                        <h3>Employee Details</h3>
                        <div className="employee-detail-view shadow-card" style={{ marginTop: '30px', padding: '20px', border: ' 1px solid #f2f2f2', borderRadius: '5px' }}>
                            {showDetails && selectedEmployee && (
                                <>
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Name</label>
                                            <h4>{selectedEmployee.employeeName}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Employee Type</label>
                                            <h4>{selectedEmployee.employeeType}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Department</label>
                                            <h4>{selectedEmployee.departmentName}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Designation</label>
                                            <h4>{selectedEmployee.designation}</h4>
                                        </div>
                                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                            <label style={{ color: 'grey' }}>Joining Date</label>
                                            <h4>{selectedEmployee.joiningDate}</h4>
                                        </div>
                                        {admin.rolelist.includes(ROLE.SHOW_EMP_SALARY) ?
                                            <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                <label style={{ color: 'grey' }}>Salary</label>
                                                <h4>{selectedEmployee.salary}</h4>
                                            </div>
                                            : ""}
                                        {admin.rolelist.includes(ROLE.SHOW_EMP_SALARY) ?

                                            <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                <label style={{ color: 'grey' }}>Variable</label>
                                                <h4>{selectedEmployee.variable}</h4>
                                            </div>
                                            : ""}
                                        {admin.rolelist.includes(ROLE.SHOW_EMP_SALARY) ?

                                            <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                <label style={{ color: 'grey' }}>Other Components</label>
                                                <h4>{selectedEmployee.otherComponent}</h4>
                                            </div>
                                            : ""}
                                        {admin.rolelist.includes(ROLE.SHOW_EMP_SALARY) ?

                                            <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                <label style={{ color: 'grey' }}>In-Hand Amount</label>
                                                <h4>{selectedEmployee.inHandAmount}</h4>
                                            </div>
                                            : ""}
                                        {admin.rolelist.includes(ROLE.SHOW_EMP_SALARY) ?

                                            <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                                                <label style={{ color: 'grey' }}>Tax Deducted</label>
                                                <h4>{selectedEmployee.taxDeducted}</h4>
                                            </div>
                                            : ""}
                                    </div>

                                    {/* Filter Form */}
                                    <div className='row' style={{ marginTop: '30px' }}>
                                        <div className="col-sm-2 col-xs-12">
                                            <label >Month</label>
                                            <select className="form-control" name="month" value={month} onChange={handleFilterChange}>
                                                <option value={''}>All Months</option>
                                                {months.map(m => (
                                                    <option key={m.value} value={m.value}>{m.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2 col-xs-12">

                                            <label >Year</label>
                                            <select className="form-control" name="year" value={year} onChange={handleFilterChange}>
                                                <option value={''}>Current Year</option>

                                                {years.map(y => (
                                                    <option key={y} value={y}>{y}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '20px' }}>

                                            <button className="btn btn-primary" onClick={() => calculateAttendance(selectedEmployee.employeeID)} style={{ padding: '10px' }}>Filter Attendance</button>
                                        </div>
                                    </div>
                                    {attendanceData.length > 0 ?
                                        <div className='phocket-table-new' style={{}}>
                                            <table className='' style={{ marginBottom: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" >Added By</th>
                                                        <th className="text-center" >EMP Name</th>
                                                        <th className="text-center" >Month</th>
                                                        <th className="text-center" >Year </th>
                                                        <th className="text-center" > Absent </th>
                                                        <th className="text-center" >Earn Leave </th>
                                                        <th className="text-center" >Half Day </th>
                                                        <th className="text-center" >Paid Leave </th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {attendanceData.length > 0 ? (
                                                        attendanceData.map((attendance, i) => (
                                                            <tr key={i}>
                                                                <td className="text-center">{attendance.addedBy}</td>
                                                                <td className="text-center">{attendance.employeeName}</td>
                                                                <td className="text-center">{attendance.month}</td>
                                                                <td className="text-center">{attendance.year}</td>
                                                                <td className="text-center">{attendance.noAbsent}</td>
                                                                <td className="text-center">{attendance.noEarnLeave}</td>
                                                                <td className="text-center">{attendance.noHalfDay}</td>
                                                                <td className="text-center">{attendance.noPaidLeave}</td>

                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" className="text-center">No attendance records found.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        : <h3 style={{ marginTop: '40px' }} className="text-center">No attendance records found.</h3>}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default EmpList;
