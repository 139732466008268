import React from 'react'
import EditLogo from '../../../../images/rewampimages/edit.png'
import UserLogo from '../../../../images/rewampimages/user.png'
import IdentityLogo from '../../../../images/rewampimages/ID Card.png'
import VerifyImage from '../../../../images/rewampimages/verifytick.png'
import NotVerified from '../../../../images/rewampimages/unverified.png'
import ResidentialLogo from '../../../../images/rewampimages/residential.png'
import ReferenceLogo from '../../../../images/rewampimages/reference.png'
import BankLogo from '../../../../images/rewampimages/bank.png'
import DocLogo from '../../../../images/rewampimages/document.png'
import ActityIcon from '../../../../images/rewampimages/ActityIcon.png'

let URLTOSEND = BASE_URL.includes('test') ? 'https://test.phocket.in/' : 'https://phocket.in/'

import { ROLE } from '../../../../utils/Constant'
import { BASE_URL } from '../../../../utils/api-factory'
import AgreementUser from '../../../containers/Admin/CustomerPages/UserAgreement.container'

const Detailing = ({ disableEsign, digitapBankData, showVerifyButton, aadharVerificationData, latestStatement, newKycData, latestStatementHanlder, saveLatestStatement, agreementHandler, agreement, showparse, errorPopup, jsonErrorData, sendToDigitap, loaderState, bankVerificationApiFunction, saveDocName, markStatusButton, markButton, buttonsForFunction, sendToFinb, UserDetailTabsHandler, recentActiviesData, reasonData, editHandler, automationDocument, closeautomationDoc, closeAdminDoc, adminDocument, bucketView, typeofDocument, documentsData, bankVerificationData, karzaData, maskAadhar, changeVeriFyStatus, bankData, deactivateNach, userReference, markincompleteapplication, appVersion, vreedhiData, userDetail, userLinkHandler, linkState, allDetail, panVerifiedstate, userScore, verifyMail, admin, randomValues, verifyHandler, saveVerify, karzaPopup, paninfo, aadharInfo, openConfirmPopup, handleBankNameList }) => (
    <div className='container-fluid details-new  animated fadeIn' style={{ background: window.innerWidth < 1800 ? '#F2F2FA' : '#fff', paddingBottom: '30px', paddingTop: '40px' }}>
        <div className='row'>

            {reasonData && reasonData.dataAnalysisRejectionReason ?
                <div className='col-sm-5 col-xs-5'>
                    {reasonData && reasonData.dataAnalysisRejectionReason ? <h5 className='reject-text'> {reasonData.dataAnalysisRejectionReason}</h5> : ""}
                </div>
                : ""}
            {/* <button className='btn btn-primary' style={{marginTop:'10px'}} onClick={()=>agreementHandler()}>Loan Agreement</button>
               {agreement? <AgreementUser allDetail={userDetail} userDetail={userDetail} />:""} */}
            {linkState === false ?
                <div className='col-sm-1 col-xs-3'>
                    <h5 onClick={() => userLinkHandler('yes')} style={{ marginTop: '25px', color: '#2B78FF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-link'></i>&nbsp;Links</h5>
                </div>
                : ""}
        </div>

        {admin != null && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && linkState == true ?
            <div className='row animated slideInLeft'>
                <button style={{ marginLeft: '15px', marginTop: '30px' }} className='btn btn-primary' onClick={() => userLinkHandler('no')}>Close Links</button>

                <div className="col-xs-12">
                    <br />
                    <p><b>Upload Document Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? URLTOSEND + "uploadDocuments/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? URLTOSEND + "uploadDocuments/" + allDetail.userId : "" : ""}</a></p>
                </div>
                <div className="col-xs-12">
                    <p><b>Feedback Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? URLTOSEND + "feedback/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? URLTOSEND + "feedback/" + allDetail.userId : "" : ""}</a></p>
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null ?
                        <p><b>E-Mandate with Rajorpay Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? URLTOSEND + "enach/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? URLTOSEND + "enach/" + userDetail.loanId : "" : ""}</a></p>
                        : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Mandate with Rajorpay Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "enach/" + userDetail.giftCardId : "" : ""}>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "enach/" + userDetail.giftCardId : "" : ""}</a></p> : ""}
                </div>
                <div className="col-xs-12">
                    {/* {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                        <p><b>E-Mandate with TechProcess Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? URLTOSEND + "eNach/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? URLTOSEND + "eNach/" + userDetail.loanId : "" : ""}</a></p> : ""} */}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null ?
                        <p><b>E-Sign Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? URLTOSEND + "esign/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? URLTOSEND + "esign/" + userDetail.loanId : "" : ""}</a></p>
                        : userDetail.giftCardId != '' && userDetail.giftCardId != null ? <p><b>E-Sign Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "esign/" + userDetail.giftCardId : "" : ""}>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "esign/" + userDetail.giftCardId : "" : ""}</a></p> : ""}
                </div>
                <div className="col-xs-12">
                    {/* {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ? */}
                    {/* // <p><b>E-Mandate with Bill-Desk Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? 'https://payment.phocket.co.in/' + "emandate/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? 'https://payment.phocket.co.in/' + "emandate/" + userDetail.loanId : "" : ""}</a></p> : ""} */}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    {userDetail.loanId != '' && userDetail.loanId != null && userDetail.typeOfProduct == 'Loan' ?
                        <p><b>E-Mandate with Signdesk Link :&nbsp;</b><a target='_blank' href={userDetail != null ? userDetail.loanId != null ? 'https://payment.phocket.co.in/' + "smandate/" + userDetail.loanId : "" : ""}>{userDetail != null ? userDetail.loanId != null ? 'https://payment.phocket.co.in/' + "smandate/" + userDetail.loanId : "" : ""}</a></p> : ""}
                    {/* //    :userDetail.giftCardId!= '' &&userDetail.giftCardId!= null? <p><b>E-Mandate with TechProcess Link :&nbsp;</b>{userDetail != null ? userDetail.giftCardId != null ? URLTOSEND + "eNach/" + userDetail.giftCardId : "" : ""}</p>:""} */}
                </div>
                <div className="col-xs-12">
                    <p><b>KYC Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? 'https://payment.phocket.co.in/' + "kyc/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? 'https://payment.phocket.co.in/' + "kyc/" + allDetail.userId : "" : ""}</a></p>
                </div>
                {console.log(allDetail.applicationStatus, 'kjhgfdfghjk')}
                {(allDetail.applicationStatus === 'Approved' || allDetail.applicationStatus === 'Disbursed' || allDetail.applicationStatus === 'Ongoing' || allDetail.applicationStatus === 'Payment Overdue' || allDetail.applicationStatus === 'Received' || allDetail.applicationStatus === 'Paid' || allDetail.applicationStatus === 'Closed' || allDetail.applicationStatus === 'Payment received Confirmation pending') ?
                    <div className="col-xs-12">
                        <p><b>KFS Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? 'https://payment.phocket.co.in/' + "kfs/" + allDetail.loanId : "" : ""}>{allDetail != null ? allDetail.loanId != null ? 'https://payment.phocket.co.in/' + "kfs/" + allDetail.loanId : "" : ""}</a></p>
                    </div>
                    : ""}
                {/* <div className="col-xs-12">
                    <p><b>Digilocker KYC Link :&nbsp;</b><a target='_blank' href={allDetail != null ? allDetail.userId != null ? 'https://payment.phocket.co.in/' + "digikyc/" + allDetail.userId : "" : ""}>{allDetail != null ? allDetail.userId != null ? 'https://payment.phocket.co.in/' + "kyc/" + allDetail.userId : "" : ""}</a></p>
                </div> */}
                {/* <div className="col-xs-12">
                    <p><b>E-Sign &amp; E-Mandate Link :&nbsp;</b>{allDetail != null ? allDetail.loanId != null ? WEB_URL + "esignEnach/" + allDetail.loanId : "" : ""}</p>
                </div> */}

                {/* <div className="col-xs-12">
                    <p><b>Current Obligation :&nbsp;</b>{allDetail != null && allDetail.loanDetailList != null && allDetail.loanDetailList.length > 0 && allDetail.loanDetailList[0].currentObligation != null ? allDetail.loanDetailList[0].currentObligation : "No Obligation"}</p>
                </div> */}
            </div>
            : null}
        < div className='row' style={{ marginTop: '30px' }}>
            <div className='col-sm-8 col-xs-12'>
                <h4 style={{ color: '#1D3557', fontWeight: '700' }}>Detailing</h4>
                <div className='details-card'>
                    <div className='details-body'>
                        <div className='details-head'>
                            <div className='row'>
                                <div className='col-sm-11 col-xs-11'>
                                    <div style={{ marginTop: '6px' }}>  <img src={UserLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '14px' }}> Personal Information</b></div>
                                </div>
                                {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                                    <div className='col-sm-1 col-xs-1 text-right4'>
                                        <img onClick={() => editHandler('personal')} src={EditLogo} style={{ width: '30px', cursor: 'pointer' }} />
                                    </div>
                                    : ""}
                                {admin != null && admin.emailId === 'SuperUser' ?
                                    <div className='col-sm-1 col-xs-1 text-right4'>
                                        <img onClick={() => editHandler('personal')} src={EditLogo} style={{ width: '30px', cursor: 'pointer' }} />
                                    </div>
                                    : ""}

                            </div>

                        </div>
                        <div style={{ padding: '10px' }}>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Email Id</label>
                                    <p>{allDetail != null ? allDetail.emailId != null ? allDetail.emailId : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Official Email Id</label>
                                    <p>{allDetail != null && allDetail.employmentDetailsPO !== null && allDetail.employmentDetailsPO !== undefined && allDetail.employmentDetailsPO.optionalEmail !== null ? allDetail.employmentDetailsPO.optionalEmail : ""}</p>

                                    {/* <p>{allDetail != null && allDetail.employmentDetailsPO !== null&&allDetail.employmentDetailsPO!==undefined && allDetail.employmentDetailsPO.optionalEmail !== null ? allDetail.employmentDetailsPO.optionalEmail : ""}</p> */}
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Mobile Number</label>
                                    <p>{allDetail != null && allDetail.mobileNumber !== null ? allDetail.mobileNumber : ""}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Alternate Number</label>
                                    <p>{allDetail != null ? allDetail.alternativeNo != null ? allDetail.alternativeNo : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>App Version</label>
                                    <p>{appVersion != '' ? appVersion : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>DOB</label>
                                    <p>{allDetail != null ? allDetail.dateOfBirth != null ? allDetail.dateOfBirth.split("T")[0] : "" : ""}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Loan Id</label>
                                    <p>{allDetail != null ? allDetail.applicationLoanId != null ? allDetail.applicationLoanId : "N/A" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Gender</label>
                                    <p>{allDetail != null ? allDetail.gender != null ? allDetail.gender : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label> Mobile Linked with Aadhar</label>

                                    <p>{aadharVerificationData != null ? aadharVerificationData.mobile != null ? aadharVerificationData.mobile : "" : ""}</p>

                                    {/* {admin.rolelist != undefined ? (
                                        admin.rolelist.indexOf(ROLE.AADHAAR_MASKED) >= 0 ? (
                                            <p><b>{allDetail != null && allDetail.isAadharMobileLinked != null ? allDetail.isAadharMobileLinked == 1 ? <span style={{ color: '#4CC78C' }}>Yes</span> : allDetail.isAadharMobileLinked == 2 ? <span style={{ color: '#F44' }}>No</span> : <span style={{ color: '#F44' }}>No</span> : ""}</b></p>
                                        ) : <p><b>{allDetail != null && allDetail.isAadharMobileLinked != null ? <span style={{ color: '#4CC78C' }}>Yes</span> : ''}</b></p>) : ""} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='details-body' style={{ marginTop: '30px' }}>
                        <div className='details-head'>
                            <div className='row'>
                                <div className='col-sm-11 col-xs-11'>
                                    <div style={{ marginTop: '6px' }}> <img src={IdentityLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '14px' }}> Identity Information & Verification</b></div>
                                </div>
                                <div className='col-sm-1 col-xs-1 text-right4'>
                                    {/* <img src={EditLogo} onClick={()=>editHandler('indentity')} style={{ width: '30px', cursor: 'pointer' }} /> */}
                                </div>
                            </div>

                        </div>
                        <div style={{ padding: '10px' }}>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Pan Number</label>
                                    <p>{allDetail != null ? allDetail.panNumber != null ? allDetail.panNumber : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Name as on PAN</label>
                                    <p>{allDetail != null && allDetail.nameAsOnPan !== null && allDetail.nameAsOnPan !== null ? allDetail.nameAsOnPan : ""}</p>
                                </div>
                                {/* <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Pan Status By Nsdl</label>
                                    <p>{allDetail != null && allDetail.nsdlPanStatus !== null ? allDetail.nsdlPanStatus : ""}</p>
                                </div> */}

                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Aadhar Number</label>
                                    {admin.rolelist != undefined ? (
                                        admin.rolelist.indexOf(ROLE.AADHAAR_MASKED) >= 0 ? (
                                            <p>{allDetail != null && allDetail.aadharNumber != null ? allDetail.aadharNumber : ""}</p>
                                        ) : <p>{maskAadhar != '' && maskAadhar !== undefined ? maskAadhar : ''}</p>) : ""}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Pan Verified</label>
                                    {admin.emailId !== 'SuperUser' ?
                                        <p><b>{karzaData && karzaData.status !== undefined && karzaData.status !== null ? karzaData.status === 'Active' ? <span style={{ color: 'green' }}>{karzaData.status}</span> : <span style={{ color: 'red' }}>{karzaData.status}</span> : "No Data Found"} </b>&nbsp; {karzaData !== '' && karzaData !== null && karzaData !== undefined && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <button className='i-button' onClick={(e) => karzaData.status === 'Active' ? karzaPopup(e, 'pan') : openConfirmPopup(e, 'pan')}><i className="fa fa-info" /></button> : ""}</p>
                                        : ""}
                                    {admin.emailId === 'SuperUser' ?
                                        <p><b>{karzaData && karzaData.status !== undefined && karzaData.status !== null ? karzaData.status === 'Active' ? <span style={{ color: 'green' }}>{karzaData.status}</span> : <span style={{ color: 'red' }}>{karzaData.status}</span> : "No Data Found"} </b>&nbsp; {karzaData !== '' && karzaData !== null && karzaData !== undefined ? <button className='i-button' onClick={(e) => karzaData.status === 'Active' ? karzaPopup(e, 'pan') : openConfirmPopup(e, 'pan')}><i className="fa fa-info" /></button> : ""}</p>
                                        : ""}
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>AADHAR Verified:</label>
                                    {admin.emailId !== 'SuperUser' ?
                                        <p><p >{aadharInfo ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />} &nbsp;{aadharInfo && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <button className='i-button' onClick={(e) => karzaPopup(e, 'aadhar')}><span className="fa fa-info" /></button> : jsonErrorData !== null && jsonErrorData !== '' && jsonErrorData.result !== null && jsonErrorData.result !== undefined && jsonErrorData.result.message !== undefined && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <button className='i-button' onClick={(e) => errorPopup(jsonErrorData)}><span className="fa fa-info" /></button> : ""} </p></p>
                                        : ""}
                                    {admin.emailId === 'SuperUser' ? <p><p >{aadharInfo ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="22px" />} &nbsp;{aadharInfo ? <button className='i-button' onClick={(e) => karzaPopup(e, 'aadhar')}><span className="fa fa-info" /></button> : jsonErrorData !== null && jsonErrorData !== '' && jsonErrorData.result !== null && jsonErrorData.result !== undefined && jsonErrorData.result.message !== undefined ? <button className='i-button' onClick={(e) => errorPopup(jsonErrorData)}><span className="fa fa-info" /></button> : ""} </p></p> : ""}

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='details-body' style={{ marginTop: '30px' }}>
                        <div className='details-head'>
                            <div className='row'>
                                <div className='col-sm-11 col-xs-11'>
                                    <div style={{ marginTop: '6px' }}> <img src={IdentityLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '14px' }}>Employment Information</b></div>
                                </div>
                                {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT) >= 0 && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?
                                    <div className='col-sm-1 col-xs-1 text-right4'>
                                        <img src={EditLogo} onClick={() => editHandler('employment')} style={{ width: '30px', cursor: 'pointer' }} />
                                    </div>
                                    : ""}
                                {admin != null && admin.emailId === 'SuperUser' ?
                                    <div className='col-sm-1 col-xs-1 text-right4'>
                                        <img src={EditLogo} onClick={() => editHandler('employment')} style={{ width: '30px', cursor: 'pointer' }} />
                                    </div>
                                    : ""}
                            </div>

                        </div>
                        <div style={{ padding: '10px' }}>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Employer Type</label>
                                    <p>{allDetail != null ? allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO !== undefined ? allDetail.employmentDetailsPO.employerType != null ? allDetail.employmentDetailsPO.employerType : "" : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Organisation Name</label>
                                    <p>{allDetail != null ? allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO !== undefined ? allDetail.employmentDetailsPO.organizationName != null ? allDetail.employmentDetailsPO.organizationName : "" : "" : ""}&nbsp;
                                        {allDetail.employmentDetailsPO !== undefined && allDetail.employmentDetailsPO.organisationVerified == true ? <img src={VerifyImage} width="22px" /> : <img src={NotVerified} width="20px" />}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Net Take Home Salary</label>
                                    <p>{allDetail != null ? allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO !== undefined ? allDetail.employmentDetailsPO.netTakeHomeSalary != null && allDetail.employmentDetailsPO.netTakeHomeSalary != 0 ? allDetail.employmentDetailsPO.netTakeHomeSalary : "" : "" : ""}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Office Address</label>
                                    <p>{allDetail != null ? allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO !== undefined ? allDetail.employmentDetailsPO.officeAddress != null ? allDetail.employmentDetailsPO.officeAddress : "" : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Total Experience</label>
                                    <p> {allDetail != null ? allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO !== undefined ? allDetail.employmentDetailsPO.totalExperience != null ? allDetail.employmentDetailsPO.totalExperience : "" : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '30px' }}>
                                    <label>Designation:</label>
                                    <p>{allDetail != null ? allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO !== undefined ? allDetail.employmentDetailsPO.designation != null ? allDetail.employmentDetailsPO.designation : "" : "" : ""}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='details-body' style={{ marginTop: '30px' }}>
                        <div className='details-head'>
                            <div className='row'>
                                <div className='col-sm-11 col-xs-11'>
                                    <div style={{ marginTop: '6px' }}> <img src={ResidentialLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '14px' }}>Residential Address</b></div>
                                </div>
                                {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT) >= 0 && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?

                                    <div className='col-sm-1 col-xs-1 text-right4'>
                                        <img src={EditLogo} onClick={() => editHandler('address')} style={{ width: '30px', cursor: 'pointer' }} />
                                    </div>
                                    : ""}
                                {admin != null && admin.emailId === 'SuperUser' ?
                                    <div className='col-sm-1 col-xs-1 text-right4'>
                                        <img src={EditLogo} onClick={() => editHandler('address')} style={{ width: '30px', cursor: 'pointer' }} />
                                    </div>
                                    : ""}
                            </div>

                        </div>
                        <div style={{ padding: '15px', minHeight: '195px' }}>
                            <div className='row'>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Current Address</label>
                                    <p>{allDetail != null ? allDetail.addressDetailsPOList != null ?
                                        <span>
                                            {allDetail.addressDetailsPOList.currentAddress != null ? allDetail.addressDetailsPOList.currentAddress + ", " : ""}
                                            {allDetail.addressDetailsPOList.currentCity != null ? allDetail.addressDetailsPOList.currentCity + ", " : ""}
                                            {allDetail.addressDetailsPOList.currentState != null ? allDetail.addressDetailsPOList.currentState + ", - " : ""}
                                            {allDetail.addressDetailsPOList.currentPincode != 0 && allDetail.addressDetailsPOList.currentPincode != null ? allDetail.addressDetailsPOList.currentPincode : ""}
                                        </span>
                                        : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Permanent Address</label>
                                    <p>{allDetail != null ? allDetail.addressDetailsPOList != null ?
                                        <span>
                                            {allDetail.addressDetailsPOList.permanentAddress != null ? allDetail.addressDetailsPOList.permanentAddress + ", " : ""}
                                            {allDetail.addressDetailsPOList.permanentCity != null ? allDetail.addressDetailsPOList.permanentCity + ", " : ""}
                                            {allDetail.addressDetailsPOList.permanentState != null ? allDetail.addressDetailsPOList.permanentState + ", - " : ""}
                                            {allDetail.addressDetailsPOList.permanentPincode != 0 && allDetail.addressDetailsPOList.permanentPincode != null ? allDetail.addressDetailsPOList.permanentPincode : ""}
                                        </span>
                                        : "" : ""}</p>
                                </div>
                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '10px' }}>
                                    <label>Aadhar Address Same As Current Address</label>
                                    <p>{allDetail != null && allDetail.aadharAndCurrentAddress != null ? allDetail.aadharAndCurrentAddress == 1 ? <span style={{ fontWeight: '700', color: '#4CC78C' }}>Yes</span> : allDetail.aadharAndCurrentAddress == 2 ? <span style={{ fontWeight: '700', color: '#F44' }}>No</span> : <span style={{ color: '#F44' }}>No</span> : ""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {userReference && userReference.length > 0 ?
                        <div className='details-body' style={{ marginTop: '30px' }}>
                            <div className='details-head'>
                                <div className='row'>
                                    <div className='col-sm-11 col-xs-11'>
                                        <div style={{ marginTop: '6px' }}> <img src={ReferenceLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '16px' }}>Reference Details</b></div>
                                    </div>
                                    {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT_REFERENCE) >= 0 && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?
                                        <div className='col-sm-1 col-xs-1 text-right4'>
                                            <img src={EditLogo} onClick={() => editHandler('refrence')} style={{ width: '30px', cursor: 'pointer' }} />
                                        </div>
                                        : ""}
                                    {admin != null && admin.emailId === 'SuperUser' ?
                                        <div className='col-sm-1 col-xs-1 text-right4'>
                                            <img src={EditLogo} onClick={() => editHandler('refrence')} style={{ width: '30px', cursor: 'pointer' }} />
                                        </div>
                                        : ""}
                                </div>

                            </div>
                            <div style={{ padding: '10px' }}>
                                {userReference && userReference.length > 0 ?
                                    <div className='phocket-table-new' >
                                        <table className="" >
                                            <thead>
                                                <tr>
                                                    <th>Reference Name</th>
                                                    <th>Number</th>
                                                    <th>Verified</th>
                                                    <th>Relationship</th>
                                                    {/* <td></td> */}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userReference ? userReference.map((data, i) => {
                                                    if (i <= 2) {
                                                        return (
                                                            <tr>
                                                                <td>{data.name}  </td>
                                                                <td>{data.number}</td>
                                                                <td >{data.verified ? <img src={VerifyImage} style={{ width: '20px', marginLeft: '20px' }} /> : <img src={NotVerified} style={{ width: '20px', marginLeft: '20px' }} />}</td>
                                                                <td>{data.relationShip}</td>


                                                            </tr>
                                                        )
                                                    }
                                                })
                                                    : ""}

                                            </tbody>
                                        </table>

                                    </div>
                                    : ""}
                            </div>
                        </div>
                        : ""}



                </div>
            </div>
            <div className='col-sm-4 col-xs-12'>
                <h4 style={{ color: '#1D3557', fontWeight: '700' }}>Recent Activities</h4>

                <div className='details-card' style={{ minHeight: '414px', height: userReference && userReference.length > 0 ? '600px' : '320px', overflow: 'auto', padding: '20px' }}>
                    {recentActiviesData !== '' && recentActiviesData.map((data, i) => {
                        return (
                            <div className='row' style={{ marginBottom: '10px', borderBottom: '1px solid #DBE9FF' }}>
                                <div className='col-sm-2 col-xs-2'>
                                    <img src={ActityIcon} width={'40px'} className='recent-img' style={{ marginBottom: '5px' }} />
                                </div>
                                <div className='col-sm-10 col-xs-10'>
                                    <p style={{ fontSize: '12px' }}>{data.comment}&nbsp;-&nbsp;<b>{data.status}</b></p>
                                    <p style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.45)' }}>{data.date}</p>
                                </div>
                            </div>
                        )
                    })}
                    <a style={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => UserDetailTabsHandler('', 3)}> <i className='fa fa-eye'></i>&nbsp;View all Activities</a>
                </div>
                <div className='details-body' style={{ marginTop: '25px' }}>
                    <div className='details-head'>
                        <div className='row'>
                            <div className='col-sm-10 col-xs-11'>
                                <div style={{ marginTop: '6px' }}>  <img src={UserLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '14px' }}> Account Information</b></div>
                            </div>
                            {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT) >= 0 && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?

                                <div className='col-sm-1 col-xs-1 text-right4'>
                                    <img src={EditLogo} onClick={() => editHandler('bank')} style={{ width: '30px', cursor: 'pointer' }} />
                                </div> : ""}
                            {admin != null && admin.emailId === 'SuperUser' ?
                                <div className='col-sm-1 col-xs-1 text-right4'>

                                    <img src={EditLogo} onClick={() => editHandler('bank')} style={{ width: '30px', cursor: 'pointer' }} />
                                </div>
                                : ""}

                        </div>

                    </div>
                    <div style={{ padding: '15px', background: '#fff', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}>
                        <div className='bank-box'>
                            <div style={{ padding: '15px', background: '#F1F7FF', borderRadius: '12px 12px 0px 0px' }}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Name As On Bank </span>
                                <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankData && bankData.nameAsBankAccount ? bankData.nameAsBankAccount : ""}</span>
                            </div>
                            <div style={{ padding: '15px', background: '#fff', }}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Bank Name </span>
                                <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankData && bankData.bankName ? bankData.bankName : ""}</span>
                            </div>
                            <div style={{ padding: '15px', background: '#F1F7FF', }}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Account Number  </span>
                                <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankData && bankData.accountNumber ? bankData.accountNumber : ""}</span>
                            </div>
                            <div style={{ padding: '15px', background: '#fff', }}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>IFSC Code </span>
                                <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankData && bankData.ifscCode ? bankData.ifscCode : ""}</span>
                            </div>
                            <div style={{ padding: '15px', background: '#F1F7FF', borderRadius: '0px 0px 12px 12px' }}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Branch  </span>
                                <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankData && bankData.branch ? bankData.branch : ""}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='details-body' style={{ marginTop: '25px' }}>
                    <div className='details-head'>
                        <div className='row'>
                            <div className='col-sm-9 col-xs-9'>
                                <div style={{ marginTop: '6px' }}>  <img src={BankLogo} style={{ width: '20px' }} />&nbsp;<b style={{ marginTop: '14px' }}> Bank Verification Details</b></div>
                            </div>
                            <div className='col-sm-1 col-xs-1 '>
                                {/* <img src={EditLogo} style={{ width: '30px', cursor: 'pointer' }} /> */}
                                {admin != null && admin.rolelist.indexOf(ROLE.OPERATIONS_JOURNEY) >= 0 && showVerifyButton === true ?
                                    <>
                                        <button className='btn btn-primary' disabled={loaderState} style={{ marginRight: '20px', padding: '10px', marginBottom: '0px', color: '#fff' }} onClick={() => bankVerificationApiFunction()}>Verify</button>
                                    </> : ""}
                            </div>
                        </div>

                    </div>
                    {showVerifyButton === true ?
                        <div style={{ padding: '15px', background: '#fff', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}>
                            <div className='bank-box'>
                                <div style={{ padding: '15px', background: '#F1F7FF', borderRadius: '12px 12px 0px 0px' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Name As On Bank </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankVerificationData && bankVerificationData.accountName ? bankVerificationData.accountName : ""}</span>
                                </div>
                                {/* <div style={{ padding: '15px', background: '#fff', }}>
                                <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Bank Name </span>
                                <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankData && bankData.bankName ? bankData.bankName : ""}</span>
                            </div> */}
                                <div style={{ padding: '15px', background: '#F1F7FF', }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Account Number  </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankVerificationData && bankVerificationData.accountNumber ? bankVerificationData.accountNumber : ""}</span>
                                </div>
                                <div style={{ padding: '15px', background: '#fff', }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>IFSC Code </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankVerificationData && bankVerificationData.ifsc ? bankVerificationData.ifsc : ""}</span>
                                </div>
                                <div style={{ padding: '15px', background: '#F1F7FF' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Is Valid </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankVerificationData && bankVerificationData.valid ? <img src={VerifyImage} width={'22px'} /> : <img src={NotVerified} width={'22px'} />}</span>
                                </div>

                                <div style={{ padding: '15px', background: '#fff', borderRadius: '0px 0px 12px 12px' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Bank Response    </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{bankVerificationData && bankVerificationData.bankResponse ? bankVerificationData.bankResponse : ""}</span>
                                </div>
                                {/* {showVerifyButton === true ?

                                    <div style={{ padding: '15px', background: '#fff', borderRadius: '0px 0px 12px 12px' }}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.80)' }}>Account Details provided by the customer matches with Details from Net Banking bank statement.</span>
                                    </div>
                                    : ""} */}

                            </div>
                        </div>
                        : ""}
                    {showVerifyButton === false ?
                        <div style={{ padding: '15px', background: '#fff', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}>
                            <div className='bank-box' style={{ minHeight: '300px' }}>
                                <div style={{ padding: '15px', background: '#F1F7FF', borderRadius: '12px 12px 0px 0px' }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Name As On Bank </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{digitapBankData && digitapBankData.name ? digitapBankData.name : ""}</span>
                                </div>

                                <div style={{ padding: '15px', background: '#fff', }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Account Number  </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{digitapBankData && digitapBankData.accountNumber ? digitapBankData.accountNumber : ""}</span>
                                </div>
                                <div style={{ padding: '15px', background: '#F1F7FF', }}>
                                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>IFSC Code </span>
                                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{digitapBankData && digitapBankData.ifscCode ? digitapBankData.ifscCode : ""}</span>
                                </div>
                                <div style={{ padding: '15px', background: '#fff', borderRadius: '0px 0px 12px 12px' }}>
                                    <span style={{ color: 'green', fontWeight: '700' }}>Account Details provided by the customer matches with Details from Net Banking bank statement.</span>
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
            </div>
        </div>
        {
            documentsData !== '' ?
                <div className='row details-new '>
                    <div className='col-sm-12 col-xs-12'>
                        <div className='details-card' style={{ padding: '0px' }}>
                            <div className='details-head'>
                                <div className='row'>
                                    <div className='col-sm-11 col-xs-11'>
                                        <div style={{ marginTop: '6px' }}>  <img src={DocLogo} style={{ width: '18px' }} />&nbsp;<b style={{ marginTop: '14px' }}> Document</b></div>
                                    </div>
                                    {admin != null && admin.emailId !== 'SuperUser' && admin.rolelist.indexOf(ROLE.EDIT) >= 0 && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?

                                        <div className='col-sm-1 col-xs-1 text-right'>
                                            <img src={EditLogo} onClick={() => editHandler('docs')} style={{ width: '30px', cursor: 'pointer' }} />
                                        </div> : ""}
                                    {admin != null && admin.emailId === 'SuperUser' ?
                                        <div className='col-sm-1 col-xs-1 text-right'>

                                            <img src={EditLogo} onClick={() => editHandler('docs')} style={{ width: '30px', cursor: 'pointer' }} />
                                        </div>
                                        : ""}
                                </div>

                            </div>
                            <div className='' style={{ padding: '20px' }}>
                                <p style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Select Document ( KYC, Aadhar card, PAN Card, Office Id, Photo ) </p>
                                <div className='row'>
                                    <div className='col-sm-5 col-xs-5'>
                                        <div className='select-new'>
                                            <select className='form-control' value={typeofDocument} onChange={(e) => bucketView(e)}>
                                                <option value="" disabled selected>Select an option</option>
                                                {documentsData.documentList && ( // Check if documentsData.documentList is not null or undefined
                                                    documentsData.documentList.photographUrl != null ||
                                                    documentsData.documentList.adharCardUrl != null ||
                                                    documentsData.documentList.panCardUrl != null
                                                ) ?
                                                    <option value='KYC'>KYC/Office ID/Photo</option>
                                                    : ""}
                                                {(documentsData.documentList && documentsData.documentList.bankStatementUrl != null && documentsData.documentList.bankStatementUrl != '' && documentsData.documentList.bankStatementUrl != undefined) || documentsData.parsedByNetbanking === true ?
                                                    <option value='BANKSTATEMENT'>Bank Statement</option>
                                                    : ""
                                                }

                                                {documentsData.documentList && documentsData.documentList.salaryProofUrl != null && documentsData.documentList.salaryProofUrl != '' ?
                                                    <option value='SALARY'>Salary Slip/Offer Letter</option>
                                                    : ""
                                                }

                                                {documentsData.documentList && (documentsData.documentList.currentAddressUrl != null ||
                                                    documentsData.documentList.loanAgreement != null ||
                                                    documentsData.documentList.stampDutyUrl != null ||
                                                    documentsData.documentList.signedLoanAggreement != null) ?
                                                    <option value='NOC'>Misc/CA/NOC</option>
                                                    : ""
                                                }
                                                {documentsData != null && documentsData.adminList != null && documentsData.adminList.length > 0 ?
                                                    < option value={'adminDoc'}> Admin Docs</option>
                                                    : ""}
                                                {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 && documentsData != null && documentsData.automationDoc != null && documentsData.automationDoc.length > 0 && automationDocument == false ?
                                                    <option value={'automation'}>Automation Documents</option>
                                                    : ""}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-sm-7 col-xs-3 '>
                                        {(admin.rolelist.indexOf(ROLE.Mark_InComplete) >= 0 || admin.rolelist.indexOf(ROLE.Mark_Complete) >= 0) ?
                                            <div className="text-right">
                                                {admin.emailId !== 'SuperUser' && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?
                                                    <button className="btn btn-primary" style={{ marginBottom: '0', marginRight: '20px', color: '#fff' }} onClick={() => markStatusButton()}>Mark Status</button>
                                                    : admin.emailId === 'SuperUser' ? <button className="btn btn-primary" style={{ marginBottom: '0', marginRight: '20px', color: '#fff' }} onClick={() => markStatusButton()}>Mark Status</button> : ""}
                                            </div>
                                            : ""}
                                        {markButton ?
                                            <div className="mark-status">
                                                {admin.rolelist.indexOf(ROLE.Mark_InComplete) >= 0 ?
                                                    <button className="btn -btn-default" style={{ background: '#FEECEB', color: '#F44' }} onClick={() => buttonsForFunction('incomplete')} >Incomplete</button>
                                                    : ""}
                                                <br />
                                                {admin.rolelist.indexOf(ROLE.Mark_Complete) >= 0 ?
                                                    <button className="btn btn-default" style={{ background: '#D7FBEA', color: '#00C851', marginTop: '10px', border: 'none' }} onClick={() => buttonsForFunction('complete')} >Complete</button>
                                                    : ""}
                                            </div>
                                            : ""}
                                        {admin.emailId === 'SuperUser' || admin.emailId === 'sunil.kumar@phocket.in' ?
                                            <div className='text-right' style={{ marginRight: '20px' }}>
                                                <button className="btn btn-default" style={{ background: '#5ac560', color: '#fff', marginTop: '10px', border: 'none', fontWeight: '700', padding: '10px' }} onClick={() => disableEsign(allDetail)} >Disable eSign</button>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                                <div className='' style={{ marginTop: '30px' }}>
                                    {documentsData != '' && typeofDocument == 'KYC' && documentsData.documentList != '' ?
                                        <div className='row'>
                                            {documentsData.documentList.photographUrl != null && documentsData.documentList.photographUrl != '' ?

                                                <div className="col-sm-4 col-xs-12" >

                                                    <div className="document-box" >
                                                        <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                            <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.photographUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.photographUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.photographUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.photographUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.photographUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.photographUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.photographUrl}>Photo Id </a>
                                                        </div>
                                                        {documentsData.documentList.photographUrl.split("type=")[1] == "img" ?
                                                            <img className="" width="100%" height="100%" src={documentsData.documentList.photographUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.photographUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.photographUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.photographUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.photographUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.photographUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.photographUrl} />
                                                            : ""}
                                                        {documentsData.documentList.photographUrl.split("type=")[1] == "pdf" ?
                                                            <object className="obj-doc" data={documentsData.documentList.photographUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.photographUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.photographUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.photographUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.photographUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.photographUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.photographUrl} ></object>
                                                            : ""}
                                                        {documentsData.documentList.photographUrl.includes("downloaddocxfile") ?
                                                            <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + documentsData.documentList.photographUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.photographUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.photographUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.photographUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.photographUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.photographUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.photographUrl + "&embedded=true"}></iframe>
                                                            : ""}
                                                    </div>

                                                </div>
                                                : ""}
                                            {documentsData.documentList.adharCardUrl != null && documentsData.documentList.adharCardUrl != '' ?

                                                <div className="col-sm-4 col-xs-12" >
                                                    <div className="document-box">
                                                        <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                            <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={
                                                                documentsData.documentList.adharCardUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.adharCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.adharCardUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.adharCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.adharCardUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.adharCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.adharCardUrl
                                                            }
                                                            >AdharCard </a>
                                                        </div>
                                                        {documentsData.documentList.adharCardUrl.split("type=")[1] == "img" ?
                                                            <img className="" width="100%" height="100%" src={
                                                                documentsData.documentList.adharCardUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.adharCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.adharCardUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.adharCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.adharCardUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.adharCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.adharCardUrl
                                                            } />
                                                            : ""}
                                                        {documentsData.documentList.adharCardUrl.split("type=")[1] == "pdf" ?
                                                            <object className="obj-doc" data={
                                                                documentsData.documentList.adharCardUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.adharCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.adharCardUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.adharCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.adharCardUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.adharCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.adharCardUrl
                                                            } ></object>
                                                            : ""}
                                                        {documentsData.documentList.adharCardUrl.includes("downloaddocxfile") ?
                                                            <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" +
                                                                documentsData.documentList.adharCardUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.adharCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.adharCardUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.adharCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.adharCardUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.adharCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.adharCardUrl
                                                                        + "&embedded=true"}></iframe>
                                                            : ""}
                                                    </div>

                                                </div>
                                                : ""}
                                            {documentsData.documentList.panCardUrl != null && documentsData.documentList.panCardUrl != '' ?

                                                <div className="col-sm-4 col-xs-12" >
                                                    <div className="document-box">
                                                        <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                            <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={
                                                                documentsData.documentList.panCardUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.panCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.panCardUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.panCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.panCardUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.panCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.panCardUrl
                                                            }>Pancard </a>
                                                        </div>
                                                        {documentsData.documentList.panCardUrl.split("type=")[1] == "img" ?
                                                            <img className="" width="100%" height="100%" src={documentsData.documentList.panCardUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.panCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.panCardUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.panCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.panCardUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.panCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.panCardUrl} />
                                                            : ""}
                                                        {documentsData.documentList.panCardUrl.split("type=")[1] == "pdf" ?
                                                            <object className="obj-doc" data={documentsData.documentList.panCardUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.panCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.panCardUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.panCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.panCardUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.panCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.panCardUrl} ></object>
                                                            : ""}
                                                        {documentsData.documentList.panCardUrl.includes("downloaddocxfile") ?
                                                            <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + documentsData.documentList.panCardUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.panCardUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.panCardUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.panCardUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.panCardUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.panCardUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.panCardUrl + "&embedded=true"}></iframe>
                                                            : ""}
                                                    </div>

                                                </div>
                                                : ""}

                                        </div>
                                        : ""}
                                    {documentsData != '' && typeofDocument == 'BANKSTATEMENT' && documentsData.documentList != '' && documentsData.parsedByNetbanking === false ?
                                        <div className='row'>
                                            <div className='' style={{ width: '85%', marginLeft: '20px' }}>Is the Bank Statement latest or not
                                                <p style={{ fontWeight: '700', color: '#00C851', display: 'flex' }}>
                                                    <select value={latestStatement} disabled={!newKycData !== '' && newKycData.editable !== undefined ? !newKycData.editable : ""} onChange={(e) => latestStatementHanlder(e)} className='form-control' style={{ width: '100px', padding: '5px' }}>
                                                        <option>Select</option>
                                                        <option value={'yes'}>Yes</option>
                                                        <option value={'No'}>No</option>

                                                    </select>
                                                    &nbsp;&nbsp;{latestStatement !== '' && latestStatement !== null && newKycData.editable ? <button className='btn btn-success' style={{ padding: '3px 14px' }} onClick={() => saveLatestStatement()}>Save</button> : ""}
                                                </p>
                                            </div>
                                            {showparse && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.CHECK_VIA_FINBIT) >= 0 && admin.emailId !== 'SuperUser' && (userDetail.statusId === '101' || userDetail.statusId === '102' || userDetail.statusId === '103' || userDetail.statusId === '105' || userDetail.statusId === '113' || userDetail.statusId === '117' || userDetail.statusId === '110' || userDetail.statusId === '109') ?

                                                <div className='text-right' style={{ display: 'flex', float: 'right' }}>
                                                    <div style={{ marginRight: '20px', cursor: 'pointer', textDecoration: 'underline', marginTop: '25px', color: '#267DFF' }} onClick={() => handleBankNameList()}>Supported Bank List</div>
                                                    <button className='btn btn-primary' onClick={() => sendToDigitap()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via Digitap</button>

                                                    {/* <button className='btn btn-primary' onClick={() => sendToFinb()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via finbit</button> */}

                                                </div> : ""}
                                            {showparse && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.CHECK_VIA_FINBIT) >= 0 && admin.emailId === 'SuperUser' ?

                                                <div className='text-right' style={{ display: 'flex', float: 'right' }}>
                                                    <div style={{ marginRight: '5', cursor: 'pointer', textDecoration: 'underline', marginTop: '25px', color: '#267DFF' }} onClick={() => handleBankNameList()}>Supported Bank List</div>
                                                    <button className='btn btn-primary' onClick={() => sendToDigitap()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via Digitap</button>

                                                    {/* <button className='btn btn-primary' onClick={() => sendToFinb()} style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}>Check via finbit</button> */}

                                                </div> : ""}
                                            {documentsData.documentList.bankStatementUrl != null && documentsData.documentList.bankStatementUrl != '' && documentsData.documentList.bankStatementUrl != undefined ?

                                                <div className="col-sm-12 col-xs-12" >

                                                    <div className="document-box">
                                                        <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                            <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.bankStatementUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.bankStatementUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.bankStatementUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.bankStatementUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.bankStatementUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.bankStatementUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.bankStatementUrl}>Bank Statement </a>
                                                        </div>
                                                        {documentsData.documentList.bankStatementUrl.split("type=")[1] == "img" ?
                                                            <img className="" width="100%" height="100%" src={documentsData.documentList.bankStatementUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.bankStatementUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.bankStatementUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.bankStatementUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.bankStatementUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.bankStatementUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.bankStatementUrl} />
                                                            : ""}
                                                        {documentsData.documentList.bankStatementUrl.split("type=")[1] == "pdf" ?
                                                            <object className="obj-doc" data={documentsData.documentList.bankStatementUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.bankStatementUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.bankStatementUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.bankStatementUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.bankStatementUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.bankStatementUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.bankStatementUrl} ></object>
                                                            : ""}
                                                        {documentsData.documentList.bankStatementUrl.includes("downloaddocxfile") ?
                                                            <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + documentsData.documentList.bankStatementUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.bankStatementUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.bankStatementUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.bankStatementUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.bankStatementUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.bankStatementUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.bankStatementUrl + "&embedded=true"}></iframe>
                                                            : ""}
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                        : typeofDocument == 'BANKSTATEMENT' && documentsData.parsedByNetbanking === true ?
                                            <h4 className='text-center' style={{ color: '#267DFF' }}><b>We have received Bank Statement via Net Banking. Kindly check Financial Analysis Section for details.</b></h4>
                                            : ""}



                                    {
                                        documentsData != '' && typeofDocument == 'SALARY' && documentsData.documentList != '' ?
                                            <div className='row'>
                                                {documentsData.documentList.salaryProofUrl != null && documentsData.documentList.salaryProofUrl != '' ?

                                                    <div className="col-sm-12 col-xs-12" >

                                                        <div className="document-box">
                                                            <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                                <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.salaryProofUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.salaryProofUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.salaryProofUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.salaryProofUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.salaryProofUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.salaryProofUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.salaryProofUrl}>Salary Proof </a>
                                                            </div>
                                                            {documentsData.documentList.salaryProofUrl.split("type=")[1] == "img" ?
                                                                <img className="" width="100%" height="100%" src={documentsData.documentList.salaryProofUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.salaryProofUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.salaryProofUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.salaryProofUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.salaryProofUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.salaryProofUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.salaryProofUrl} />
                                                                : ""}
                                                            {documentsData.documentList.salaryProofUrl.split("type=")[1] == "pdf" ?
                                                                <object className="obj-doc" data={documentsData.documentList.salaryProofUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.salaryProofUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.salaryProofUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.salaryProofUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.salaryProofUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.salaryProofUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.salaryProofUrl} ></object>
                                                                : ""}
                                                            {documentsData.documentList.salaryProofUrl.includes("downloaddocxfile") ?
                                                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + documentsData.documentList.salaryProofUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.salaryProofUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.salaryProofUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.salaryProofUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.salaryProofUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.salaryProofUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.salaryProofUrl + "&embedded=true"}></iframe>
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""
                                    }
                                    {
                                        documentsData != '' && typeofDocument == 'NOC' && documentsData.documentList != '' ?

                                            <div className='row'>
                                                {documentsData.documentList.currentAddressUrl != null && documentsData.documentList.currentAddressUrl != '' ?
                                                    <div className="col-sm-4 col-xs-12" >
                                                        <div className="document-box">
                                                            <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                                <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.currentAddressUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.currentAddressUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.currentAddressUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.currentAddressUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.currentAddressUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.currentAddressUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.currentAddressUrl}>Current Address </a>
                                                            </div>
                                                            {documentsData.documentList.currentAddressUrl.split("type=")[1] == "img" ?
                                                                <img className="" width="100%" height="100%" src={documentsData.documentList.currentAddressUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.currentAddressUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.currentAddressUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.currentAddressUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.currentAddressUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.currentAddressUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.currentAddressUrl} />
                                                                : ""}
                                                            {documentsData.documentList.currentAddressUrl.split("type=")[1] == "pdf" ?
                                                                <object className="obj-doc" data={documentsData.documentList.currentAddressUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.currentAddressUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.currentAddressUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.currentAddressUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.currentAddressUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.currentAddressUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.currentAddressUrl} ></object>
                                                                : ""}
                                                            {documentsData.documentList.currentAddressUrl.includes("downloaddocxfile") ?
                                                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + documentsData.documentList.currentAddressUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.currentAddressUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.currentAddressUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.currentAddressUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.currentAddressUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.currentAddressUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.currentAddressUrl + "&embedded=true"}></iframe>
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {documentsData.documentList.loanAgreement != null && documentsData.documentList.loanAgreement != '' ?

                                                    <div className="col-sm-4 col-xs-12">
                                                        <div className="document-box">
                                                            <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                                <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.loanAgreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.loanAgreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.loanAgreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.loanAgreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.loanAgreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.loanAgreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.loanAgreement}>Loan Agreement </a>
                                                            </div>
                                                            {documentsData.documentList.loanAgreement.split("type=")[1] === "img" ?
                                                                <img className="" width="100%" height="100%" src={documentsData.documentList.loanAgreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.loanAgreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.loanAgreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.loanAgreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.loanAgreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.loanAgreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.loanAgreement} />
                                                                : ""}
                                                            {documentsData.documentList.loanAgreement.split("type=")[1] === "pdf" ?
                                                                <object className="obj-doc" data={documentsData.documentList.loanAgreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.loanAgreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.loanAgreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.loanAgreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.loanAgreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.loanAgreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.loanAgreement} ></object>
                                                                : ""}
                                                            {documentsData.documentList.loanAgreement.includes("downloaddocxfile") ?
                                                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + (documentsData.documentList.loanAgreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.loanAgreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.loanAgreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.loanAgreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.loanAgreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.loanAgreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.loanAgreement) + "&embedded=true"}></iframe>
                                                                : ""}
                                                        </div>


                                                    </div>
                                                    : ""}
                                                {documentsData.documentList.stampDutyUrl != null && documentsData.documentList.stampDutyUrl != '' ?
                                                    <div className="col-sm-4 col-xs-12" >
                                                        <div className="document-box">
                                                            <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                                <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.stampDutyUrl.includes('//phocket.in')
                                                                    ? documentsData.documentList.stampDutyUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.stampDutyUrl.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.stampDutyUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.stampDutyUrl.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.stampDutyUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.stampDutyUrl}>Stamp Duty </a>
                                                            </div>
                                                            <object className="obj-doc" data={documentsData.documentList.stampDutyUrl.includes('//phocket.in')
                                                                ? documentsData.documentList.stampDutyUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                : documentsData.documentList.stampDutyUrl.includes('//adminsupport.phocket.in')
                                                                    ? documentsData.documentList.stampDutyUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.stampDutyUrl.includes('//admin.phocket.in')
                                                                        ? documentsData.documentList.stampDutyUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.stampDutyUrl} ></object>

                                                        </div>

                                                    </div>
                                                    : ""}
                                                {documentsData.documentList.signedLoanAggreement != null && documentsData.documentList.signedLoanAggreement != '' ?
                                                    <div className="col-sm-4 col-xs-12" >
                                                        <div className="document-box">
                                                            <div className="" style={{ marginBottom: '10px', fontWeight: '700' }}>
                                                                <a target="_blank" className="" style={{ color: '#000', marginBottom: '20px' }} href={documentsData.documentList.signedLoanAggreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.signedLoanAggreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.signedLoanAggreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.signedLoanAggreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.signedLoanAggreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.signedLoanAggreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.signedLoanAggreement}>Signed LoanAggreement</a>
                                                            </div>
                                                            {documentsData.documentList.signedLoanAggreement.split("type=")[1] == "img" ?
                                                                <img className="" width="100%" height="100%" src={documentsData.documentList.signedLoanAggreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.signedLoanAggreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.signedLoanAggreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.signedLoanAggreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.signedLoanAggreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.signedLoanAggreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.signedLoanAggreement} />
                                                                : ""}
                                                            {documentsData.documentList.signedLoanAggreement.split("type=")[1] == "pdf" ?
                                                                <object className="obj-doc" data={documentsData.documentList.signedLoanAggreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.signedLoanAggreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.signedLoanAggreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.signedLoanAggreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.signedLoanAggreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.signedLoanAggreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.signedLoanAggreement} ></object>
                                                                : ""}
                                                            {documentsData.documentList.signedLoanAggreement.includes("downloaddocxfile") ?
                                                                <iframe className="obj-doc" src={"https://docs.google.com/gview?url=" + documentsData.documentList.signedLoanAggreement.includes('//phocket.in')
                                                                    ? documentsData.documentList.signedLoanAggreement.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : documentsData.documentList.signedLoanAggreement.includes('//adminsupport.phocket.in')
                                                                        ? documentsData.documentList.signedLoanAggreement.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : documentsData.documentList.signedLoanAggreement.includes('//admin.phocket.in')
                                                                            ? documentsData.documentList.signedLoanAggreement.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : documentsData.documentList.signedLoanAggreement + "&embedded=true"}></iframe>
                                                                : ""}
                                                        </div>

                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""
                                    }

                                </div>
                                {
                                    documentsData != null && documentsData.adminList != null && documentsData.adminList.length > 0 && adminDocument == true ?
                                        <div className="row">
                                            <div className="text-right" style={{ marginTop: '10px', marginRight: '10px' }}>
                                                <button className="btn btn-primary" onClick={() => closeAdminDoc()} >Close Admin Documents</button>
                                            </div>
                                            <h3 className="text-center blue-text">Admin Docs</h3>
                                            {documentsData.adminList.map((imgURL, i) => {
                                                return (
                                                    <div key={i} className="col-sm-4 col-xs-12">
                                                        <div className="document-box">
                                                            <div style={{ marginBottom: '10px', fontWeight: '700', color: '#000' }}>
                                                                <a target="_blank" style={{ color: '#000', fontWeight: '700' }} className="" href={imgURL.documentUrl.includes('//phocket.in')
                                                                    ? imgURL.documentUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : imgURL.documentUrl.includes('//adminsupport.phocket.in')
                                                                        ? imgURL.documentUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : imgURL.documentUrl.includes('//admin.phocket.in')
                                                                            ? imgURL.documentUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.documentUrl}>
                                                                    <h5 style={{ fontWeight: '700' }}>{imgURL.type}</h5>
                                                                </a>
                                                            </div>
                                                            {imgURL.documentUrl != null && imgURL.documentUrl.includes("downloaddocxfile") ?
                                                                <iframe src={"https://docs.google.com/gview?url=" + imgURL.documentUrl.includes('//phocket.in')
                                                                    ? imgURL.documentUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                    : imgURL.documentUrl.includes('//adminsupport.phocket.in')
                                                                        ? imgURL.documentUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                        : imgURL.documentUrl.includes('//admin.phocket.in')
                                                                            ? imgURL.documentUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.documentUrl + "&embedded=true"} className="obj-doc"></iframe>
                                                                : imgURL.documentUrl != null && imgURL.documentUrl.split("type=")[1] == "img" ?
                                                                    <img className="" width="100%" height="100%" src={imgURL.documentUrl.includes('//phocket.in')
                                                                        ? imgURL.documentUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                        : imgURL.documentUrl.includes('//adminsupport.phocket.in')
                                                                            ? imgURL.documentUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                            : imgURL.documentUrl.includes('//admin.phocket.in')
                                                                                ? imgURL.documentUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.documentUrl} />
                                                                    : <object className="obj-doc" data={imgURL.documentUrl.includes('//phocket.in')
                                                                        ? imgURL.documentUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                        : imgURL.documentUrl.includes('//adminsupport.phocket.in')
                                                                            ? imgURL.documentUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                            : imgURL.documentUrl.includes('//admin.phocket.in')
                                                                                ? imgURL.documentUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.documentUrl} ></object>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        : null
                                }
                                {
                                    admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                        automationDocument == true ?
                                            <div className="row">
                                                <div className='text-right' style={{ marginTop: '10px', marginRight: '10px' }}>
                                                    <button className="btn btn-primary" onClick={e => closeautomationDoc()}>Close Automation Documents</button>
                                                </div>
                                                <h3 className="text-center blue-text">Automation Docs</h3>
                                                {documentsData.automationDoc.map((imgURL, i) => {
                                                    return (
                                                        <div key={i} className="col-sm-4 col-xs-12">
                                                            <div className="document-box">
                                                                <select className="form-control" onChange={e => saveDocName(e, imgURL.imageUrl)} defaultValue={imgURL.type}>
                                                                    <option value="">Select Name</option>
                                                                    <option value="photograph">photograph</option>
                                                                    <option value="adharCard">adharCard</option>
                                                                    <option value="panCard">panCard</option>
                                                                    <option value="bankStatement">bankStatement</option>
                                                                    <option value="salaryProof">salaryProof</option>
                                                                    <option value="currentAddressProof">Current Address Proof</option>
                                                                </select>
                                                                <a target="_blank" className="text-white" href={
                                                                    imgURL.imageUrl.includes('//phocket.in')
                                                                        ? imgURL.imageUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                        : imgURL.imageUrl.includes('//adminsupport.phocket.in')
                                                                            ? imgURL.imageUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                            : imgURL.imageUrl.includes('//admin.phocket.in')
                                                                                ? imgURL.imageUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.imageUrl
                                                                }>
                                                                    {imgURL.imageUrl != null && imgURL.imageUrl.split("type=")[1] == "img" ?
                                                                        <img className="" width="100%" height="100%" src={imgURL.imageUrl.includes('//phocket.in')
                                                                            ? imgURL.imageUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                            : imgURL.imageUrl.includes('//adminsupport.phocket.in')
                                                                                ? imgURL.imageUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                                : imgURL.imageUrl.includes('//admin.phocket.in')
                                                                                    ? imgURL.imageUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.imageUrl} />
                                                                        :
                                                                        <object className="obj-doc" data={imgURL.imageUrl.includes('//phocket.in')
                                                                            ? imgURL.imageUrl.replace('//phocket.in', '//adminsupport.phocket.co.in')
                                                                            : imgURL.imageUrl.includes('//adminsupport.phocket.in')
                                                                                ? imgURL.imageUrl.replace('//adminsupport.phocket.in', '//adminsupport.phocket.co.in')
                                                                                : imgURL.imageUrl.includes('//admin.phocket.in')
                                                                                    ? imgURL.imageUrl.replace('//admin.phocket.in', '//adminsupport.phocket.co.in') : imgURL.imageUrl} ></object>
                                                                    }
                                                                </a>
                                                            </div>
                                                        </div>

                                                    )
                                                })}

                                            </div>
                                            : null : null
                                }
                            </div>

                        </div>
                    </div>

                </div>

                : ""
        }


    </div >
)
export default Detailing